ul.pagination {
    justify-content: end
}

.pagination li{
    margin-inline-start: 12.5px;
    margin-inline-end: 12.5px;
}


.pagination li {
    color: rgba(69, 69, 69, 0.5) !important;;
}

.pagination li.selected {
    color: var(--yellow)!important;
}