.generalSettings{
    form{
        margin-top: 39px;
        
        .generalSettingsForm{
            width: 60%;

            input , input:focus , textarea , textarea:focus {
                background: #FCFDFE;
                border: 1px solid #858484;
                box-sizing: border-box;
                border-radius: 5px;
                padding: 11px 15px;
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.3px;
            }

            textarea {
                resize: none;
                height: 100px;
            }

            .LikeInput{
                padding: 11px 0;
                background: #FCFDFE;
                border: 1px solid #858484;
                background-clip: padding-box;
                -webkit-appearance: none;
                appearance: none;
                border-radius: 5px;
                height: 43.7px;

                .tagContainer{
                    background: linear-gradient(0deg, #F5F5F5, #F5F5F5), linear-gradient(0deg, #F5F5F5, #F5F5F5), linear-gradient(0deg, #F5F5F5, #F5F5F5), linear-gradient(0deg, #F5F5F5, #F5F5F5), #F5F5F5;
                    border-radius: 3px;
                    padding: 2px;
                    margin-right: 10px;
                    color: var(--main-color);
                    font-family: 'Mulish';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 10px;
                    letter-spacing: 0.3px;
                    white-space: nowrap;
                    display: inline-block!important;

                    .removeTag{
                        margin-left: 5px;
                        cursor: pointer;
                    }
                }

                input:focus{
                    border: none!important;
                }

                .tagInput{
                    padding: 5px 15px;
                }
            }
            
            .tagInput:focus{
                outline: 0;
            }
    
            .imageWrapper{
                padding-top: 13px;
                padding-bottom: 13px;
                background: #fcfdfe;
                border: 1px solid #60626e;
                box-sizing: border-box;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
    
                img{
                    width: 60px;
                    height: 45px;
                }
    
                span{
                    font-family: 'Mulish';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 9.17241px;
                    line-height: 13px;
                    text-align: center;
                    letter-spacing: 0.131034px;
                    color: #454545;
                    text-transform: capitalize;
                }
            }
    
            /* button{
                background: #454545;
                border-radius: 32px;
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                letter-spacing: 0.2px;
                color: #fff;
                padding: 10px 40px;
                border: none;
                text-transform: capitalize;
            } */
        }

    }
}
@media screen and (max-width:1200px){
    .generalSettings form .generalSettingsForm{
        width: 90%;
    }
    
}
@media screen and (max-width:992px){
    .generalSettings form .generalSettingsForm{
        width: 100%;
    }
    
}