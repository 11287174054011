.headerWraper {
    padding: 30px 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3{
        text-transform: capitalize;
    }

    .headerBtn , .headerBtn:hover{
        background-color: var(--yellow);
        color: #fff;
        border:1px solid var(--yellow);
        padding: 9px 18px;
        text-transform: capitalize;
        text-align: center;
        text-decoration: none;
        margin-left: 30px;
    }
    .headerBtn:hover{
        background-color: #fff ;
        color: var(--yellow);
    }

    .searchInput {
        background: #ffffff;
        border: 1px solid #dfe0eb;
        box-sizing: border-box;
        border-radius: 3px;
        height: 35px;
    }

    .search-icon {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    .userName {
        font-family: "Mulish", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 20px;
        text-align: right;
        letter-spacing: 0.2px;
        color: #252733;
        padding-left: 30px;
        border-left: 1px solid #dfe0eb;
        text-transform: capitalize;
    }

    .userImg {
        margin-left: 12px;
        width: 44px;
        height: 44px;
        border-radius: 50%;        
        img {
            border-radius: 50%;
            padding: 2px;
            border: 1.5px solid #dfe0eb;
            width: 44px;
            height: 44px;
            object-fit: contain;
        }
    }

    .notificationDot {
        padding: 5px;
        background-color: #3751ff;
        border-radius: 50%;
        right: 1px;
    }

    .notification {
        border: none;
        background: none;
    }
}

.notification {
    border: none;
    background: none;
}
.notificationHeader {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
}
.notificationDot {
    padding: 5px;
    background-color: #3751FF;
    border-radius: 50%;
    right: 1px;
}
.notificationDropdown{
    width: 260px;
    padding: 0px;
    height: 300px;
    overflow-y: scroll;
}
.notificationDropdown::-webkit-scrollbar {
    width: 5px;
}
.notificationDropdown::-webkit-scrollbar-track {
    background-color: #252733;
} 
/* width */
.notificationDropdown::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.notificationDropdown::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.notificationDropdown::-webkit-scrollbar-thumb {
    background: #d3ad78;
    border-radius: 5px;
}

/* Handle on hover */
.notificationDropdown::-webkit-scrollbar-thumb:hover {
    background: #a7895f;
}

.dropdownItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    font-size: 10px;
    color: #252733;
    font-family: 'Mulish',sans-serif;
}
.dropdownItem .user{
    font-size: 12px;
    font-weight: 500;
}
.dropdownItem .issue{
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
}
.dropdownItem .hour {
    align-self: flex-end;
    white-space: nowrap;
}
.linkNotification{
    text-decoration: none;
    cursor: pointer;
}
.linkNotification li:hover{
    background-color: #88888829!important;
}
.unreadNotification{
    background-color: #88888829!important;
}
.notificationDropdown li {
    padding: 5px 10px;
}
.notificationDropdown .icon{
    font-size: 15px;
}
.dropdown-menu.py-0.show{
    inset: 14px 0 auto auto !important;
}
.dropdown-menu.py-0.show li{
    cursor: pointer;
    font-size: 13px;
}
@media screen and (max-width:992px) {
    .sideBarToggler {
        width: fit-content;
        background-color: var(--yellow) !important;
        align-self: center;
        box-shadow: none;
        outline: none;
        &:focus{
            background-color: var(--yellow) !important;
            box-shadow: none !important;
            outline: none !important;
        }
        svg path{
            fill:#fff;
        }
    }
    .headerWraper .headerBtn{
        padding: 7px 13px;
    }
}
@media screen and (max-width:600px) {
    .headerWraper{
        flex-wrap: wrap;
        flex-direction: column-reverse;
        align-items:initial;
    }
    .headerWraper .userImg img{
        width: 35px;
        height: 35px;
    }
}

