.websitePages{
    .overviewPage{
        width: 100%;
        /* height: 46px; */
        padding: 14px 24px;
        background-color: #F6F6F6;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;

        p{
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.3px;
            text-transform: capitalize;
            color: #454545;
            margin-bottom: 0;
        }
    }
}