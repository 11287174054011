table{
    tbody{
        tr{
            .sliderImg{
                width: 90px;
            }
        }
        td{
            .partnerLogoBoredr{
                border: 1.5px solid #DFE0EB;
                border-radius: 50%;
                width: 55px;
                height: 55px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 4px;
                border: 1.5px solid #DFE0EB;

                .userPhoto{
                    cursor: unset;
                    width: 100%;
                    height: 100%; 
                    margin:auto;
                    object-fit: contain;
                    border-radius:50%;
                }
                
            }

            .adminRoleLabel , .userRoleLabel{
                color: #fff;
                text-align: center;
                text-transform: capitalize;
                padding: 4px;
                width: 90px;       
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 400;
                font-size: 14.5px;
                line-height: 16px;
                letter-spacing: 0.3px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .adminRoleLabel{
                background-color: var(--yellow);
            }

            .userRoleLabel{
                background-color: var(--main-color);
            }

            div{
                img , label{
                    margin-right: 14px;
                    cursor: pointer;
                }
            }
        }
        td.desc{
            /* width:25%; */
            max-width: 190px;
        }
        td.desc div.descriptionTd{
            width:100%;
            max-width: 100%;
            display: block;
            white-space: nowrap;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

/****** contact requests table ********/
.contact-requests{
    table>tbody>tr:nth-child(4n+1)>* , table>tbody>tr:nth-child(4n+2)>*{
        background-color: #f5f5f5!important;
    }

}

.addBlog{
    form{
        margin-top: 25px;

        .imageWrapper{
            width: 100%;
            height: 130px;
            padding: 0!important;
            background: #fcfdfe;
            border: 0.5px solid #60626e;
            box-sizing: border-box;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            img{
                width: 55px;
                height: 40px;
            }

            span{
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 700;
                font-size: 9.17241px;
                line-height: 13px;
                text-align: center;
                letter-spacing: 0.131034px;
                color: #454545;
            }

            div{
                width: 101%;
                border-radius: 5px;
                height: 130px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
            }

            .reloadAnotherIcon{
                top: -20px;
                right: -15px;
                cursor: pointer;
                img{
                    width: 23px;
                }
            }
        }

        /* textarea{
            height: 90px;
            resize: none;
        } */

        button{
            width: 11.25rem;
        }
    }
}