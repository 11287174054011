@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,300&display=swap');

body {
  height: 100%;
}

div#root {
    height: 100%;
}
:root {
  --main-color:#7a7a7a;
  --yellow:#7C0120;
}

input:focus , select:focus , textarea:focus , .phoneInput input:focus{
  border: 1px solid #A58C3B !important;
  outline: none !important;
  box-shadow: none!important;
}
input[type=checkbox]{
  cursor: pointer;
}
.loaderPage{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

/************** global classes ***************/
.subside_action_wrapper{
  display: flex;
  justify-content: space-between;
  background-color: #FCFDFE!important;
}

.componentGlobalWrapper{
    min-height: calc(100vh - 180px);
    background-color: #FFFFFF;
    border: 1px solid #DFE0EB;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 1.875rem;
    margin-bottom: 3.125rem;
}

.pageSubtitle{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 22.5px;
    line-height: 30px;
    letter-spacing: 0.3px;
    color: #454545;
    text-transform: capitalize;
}

.languageDropdown{
  background: #FFFFFF;
  border: 1px solid #454545;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 9px 10px;
  color: #9FA2B4;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 175px;
  margin-right: 10px;
}

.custom-lang-dropdown{
  width: 175px!important;
}

.custom-lang-dropdown li, .custom-bulk-dropdown li{
  color: var(--main-color);
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  text-transform: capitalize;
  padding: 4px 1px;
  cursor: pointer;
}

.custom-lang-dropdown li:hover , .custom-bulk-dropdown li:hover{
  background-color: #45454516;
}
/***************************************** images *****************************************/
.globalImageWrapper{
  width: 200px;
  height: 140px;
  padding: 0!important;
  background: #fcfdfe;
  border: 0.5px solid #60626e;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.globalImageWrapper .cloudIcon{
  width: 55px;
  height: 40px;
}

.globalImageWrapper span{
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 9.17241px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 0.131034px;
  color: #454545;
  text-transform: none;
}

.globalPreviewedImage{
  width: calc(100% + 2px);
  height: 142px;
  border-radius: 5px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.globalRemoveImage{
  position: absolute;
  top: -13px;
  right: -13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.globalRemoveImage img{
  width: 23px;
  background-color: white;
  border-radius: 50%;
}

/***** bulk actions dropdown *****/
.bulkDropdown{
  width: 150px;
  color: #3F3F3F!important;
}

.custom-bulk-dropdown{
  width: 150px!important;
  min-width: unset!important;
  padding-bottom: 0!important;
}

/***** export dropdown *******/
.exportDropdown{
  width: 140px;
}
.exportDropdown::after{
  display: none!important;
}

.export-dropdown-menu{
  width: 140px;
  min-width: unset!important;
  background: #FFFFFF;
  /* border: 1px solid #454545; */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px 0px 5px 5px;
  padding: 0!important;
}
.export-dropdown-menu li{
  padding: 8px 20px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.3px;
  color: #454545;
  text-transform: uppercase;
  cursor: pointer;
}

.export-dropdown-menu li:hover{
  background-color: #45454516;
}


/****** select box *******/
.selectParent{
  width: 100%;
  height: 43.7px;
  overflow: hidden;
  background: url(./assets/images/ExpandArrow.png) no-repeat;
  background-position: calc(100% - 10px) center;
  background-color: #FCFDFE;
  display: inline-block;
  position: relative;
  border-radius: 5px;
}

.selectParent select , .selectParent select:focus {
  background: transparent;
  appearance:none;
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */                
  width: 100%;
  height: 43.7px;
  border-radius: 5px;
  border: 1px solid #858484;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* color: rgba(75, 80, 109 , 0.4); */
  text-transform: capitalize;
  line-height: 20px;
  letter-spacing: 0.3px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  padding: 11px 15px;
}

/* option:checked{
  color: var(--main-color)!important;
}

option:not(:checked){
  color: #9FA2B4!important;
} */

/****** labels , inputs and textarea ********/
/* label{
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #9FA2B4;
  margin-bottom: 8px;
}

input , textarea ,.phoneInput input ,.react-tel-input .form-control{
  box-sizing: border-box !important;
  background: #FCFDFE !important;
  border: 1px solid #858484 !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  padding: 11px 13px !important;
  font-family: 'Mulish' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px!important;
  letter-spacing: 0.3px !important;
  color: var(--main-color) !important; 
} */

.react-tel-input .form-control{
  width: 100% !important;
  height: unset!important;
  padding: 11px 50px !important;
} 
.react-tel-input .flag-dropdown{
  background: #FCFDFE !important;
  border: 1px solid #858484 !important;
}
input::placeholder{
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #4B506D;
  opacity: 0.4;
  text-transform: capitalize;
}


.deleteBtn{
  background-color: #CD3D3D;
  margin-right: 17px;
  border-radius: 32px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #fff;
  padding: 10px 40px;
  border: none;
  text-transform: capitalize;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s;
  min-width: 177px; 
  max-width: 177px !important;
}

.deleteBtn:hover{
  color: #fff;
  background-color: #cd3d3ddb;
}

/****** table global style ***********/
table{
  /* border-top: 1px solid #DFE0EB; */
  border: 1px solid #DFE0EB;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: #f5f5f5!important;
}

th{
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  text-transform: capitalize;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #454545;
}

td{
  vertical-align: middle;
  text-transform: capitalize;
  font-size: 14px;
}

.table>:not(:first-child){
  border-top: 0!important;
}

.table>:not(caption)>*>*{
  padding: 14px 25px!important;
  border-bottom: 0!important;
}

td.sliderImg img{
  object-fit: cover;
  width: 50%;
  height: 90px;
}

.contactMsgRow{
  border: 1px solid #C4A96B!important;
}

.envelopWrapper{
  margin-right: 14px;
  background-color: #fff!important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

/*********** editor global style ***************/
.rdw-option-wrapper img{
  width: 80%;
}
.public-DraftStyleDefault-ltr{
  text-align: inherit!important;
}
.rdw-editor-wrapper {
  height: inherit;
  width: 99%;
}

.rdw-option-wrapper[title="Embedded"], .rdw-option-wrapper[title="Image"], .rdw-option-wrapper[title="Link"]{
  display: block;
}

.rdw-option-wrapper[title="Color Picker"] ,
.rdw-option-wrapper[title="Remove"] ,
.rdw-option-wrapper[title="Undo"] ,
.rdw-option-wrapper[title="Redo"] ,
.rdw-option-wrapper[title="Unlink"] ,
.rdw-option-wrapper[title="Left"],
.rdw-option-wrapper[title="Right"],
.rdw-option-wrapper[title="Center"],
.rdw-option-wrapper[title="Subscript"],
.rdw-option-wrapper[title="Indent"],
.rdw-option-wrapper[title="Outdent"],
.rdw-option-wrapper[title="Justify"],
.rdw-option-wrapper[title="Superscript"],
.rdw-option-wrapper[title="Strikethrough"],
.rdw-option-wrapper[title="Italic"],
.rdw-option-wrapper[title="Monospace"],
.rdw-fontsize-wrapper,
.rdw-fontfamily-wrapper,
.rdw-colorpicker-wrapper,
.rdw-embedded-wrapper,
.rdw-link-wrapper,
.rdw-block-wrapper ,
.rdw-image-wrapper ,
.rdw-emoji-wrapper{
  display: none!important;
}

.rdw-inline-wrapper{
  margin-right: 5px;
}

.rdw-option-wrapper{
  height:fit-content;
  border: none!important;
} 

.rdw-editor-toolbar {
  border: none!important;
  padding: 10px 12px;
}

.rdw-editor-wrapper{
  display: flex;
  flex-direction: column-reverse;
  background-color: #FCFDFE;
  border: 1px solid #858484 !important;
  box-sizing: border-box;
  border-radius: 5px;
  height: 200px; 
  width: 100%;
}
.textEditorContainer{
  max-width: calc(100vw - 30vw);
}
.rdw-editor-main{
  padding: 5px 10px;
  /* overflow-y: scroll!important; */
  padding: 0px 22px;
  height: 70% !important;
  width: inherit;
}

/* arabic right text editor */
div[dir="rtl"] .rdw-dropdown-carettoopen,
 div[dir="rtl"] .rdw-dropdown-carettoclose {
  right: unset;
  left: 10%;
}
 div[dir="rtl"] .public-DraftStyleDefault-ltr {
  direction: rtl;
}

.public-DraftStyleDefault-block{
  width: fit-content!important;
  max-width: 100%!important;
}

.public-DraftStyleDefault-rtl{
  text-align: inherit!important;
}

/****** tabs style ********/
.tablinks{
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 6px;
  padding-left: 6px;
  margin-right: 6px;
  margin-left: 6px;
  cursor: pointer;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.3px;
  color: #454545;
  text-transform: capitalize;
}

.tabcounter{
  color: #9FA2B4;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
}

.nav-tabs{
  border: none!important;
}

.tablinks.active{
  border-bottom: 3px solid var(--yellow);
}

.tablinks.active .tabcounter{
  color: var(--yellow);
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
}

.separator{
  height: 20px;
  width: 1px;
  background-color: #DFE0EB;
}


/*********** toggle switch ************/

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 15px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  left: 3.5px;
  bottom:2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #36D2A3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #36D2A3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(11px);
  -ms-transform: translateX(11px);
  transform: translateX(11px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 15px;
}

.slider.round:before {
  border-radius: 50%;
}

/******* featured badge *********/
.featuredBadge , .notFeaturedBadge{
  text-transform: capitalize;
  padding: 6px 5px!important;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-size: 10px!important;
}

.featuredBadge{
  background-color: var(--yellow);
}

.notFeaturedBadge{
  background-color: var(--main-color);
}


/************* modal global style  ************/
.modal-header{
  border-bottom : none!important;
  padding-top: 30px!important;
  padding-right: 30px!important;
  margin-left: auto;
}

/* .modal-content{
  border-radius: 41px!important;
} */

.modal-body{
  padding: 0 30px 30px 30px!important;
}

.modal-body label{
  text-transform: capitalize;
}

.modal-body p{
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: var(--main-color);
  margin-bottom: 4px;
}

.closeModalIcon{
  cursor: pointer;
}

.form-floating>.form-control , .form-floating>.form-control:focus{
  border: none!important;
  border-bottom: 1px solid var(--yellow)!important;
  line-height: 10px!important;
  padding-bottom: 0!important;
  padding-left:0!important;
  margin-left:3px!important;
  height: 50px!important;
  box-shadow: none!important;
  border-radius: 0!important;
  background-color: #fff!important;
}
.form-floating>label{
  padding-left: 0!important;
}
.form-floating>label>span{
  text-transform: capitalize;
}
.form-floating input:-internal-autofill-selected{
  background-color: #fff!important;
}

.validateToDelete{
  font-size: 12px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
}

.cancelBtn{
  background: transparent;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: var(--main-color);
  padding: 10px 15px;
  border: none;
  text-transform: capitalize;
  text-decoration: none;
  cursor: pointer;
}


/************ upload image preview  *********************/
.imageWrapper{
  padding-top: 13px;
  padding-bottom: 13px;
  background: #fcfdfe;
  border: 1px solid #60626e;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
/* 
.imageWrapper img{
    width: 55px;
   height: 40px;
} */

.imageWrapper span{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 9.17241px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.131034px;
    color: #454545;
}

.roundedIimageWrapper{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  padding: 12px!important;
  background: #fcfdfe;
  border: 0.5px solid #60626e;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.roundedIimageWrapper span{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 9.17241px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.131034px;
    color: #454545;
}

.roundedIimageWrapper .reloadAnotherIcon{
  position: absolute;
  top: -2px;
  right: -3px;
  cursor: pointer;
}

.roundedIimageWrapper .reloadAnotherIcon img{
  width: 21px;
}

.roundedIimageWrapper .previewedImg{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

/************** individual image wrapper ***************/
.individualImageWrapper{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  padding: 10px!important;
  background: #fcfdfe;
  border: 1.5px solid #DFE0EB;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.individualImageWrapper span{
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 9.17241px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 0.131034px;
  color: #454545;
  text-transform: capitalize;
}
.individualImageWrapper>img{
  width: 55px;
}

.individualImageWrapper .previewedImg{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.individualImageWrapper .reloadAnotherIcon{
  position: absolute;
  top: -18px;
  cursor: pointer;
}

.individualImageWrapper .reloadAnotherIcon img{
  width: 30px;
}

/* #adminForm input , #adminForm select , #memberForm input , #memberForm select{
  border: 1.5px solid #DFE0EB;
} */

/************ sweet alert *****************/
  .swal2-popup {
    font-family: 'Mulish';
    font-size: 18px!important;
    color: var(--main-color);
    font-style: normal;
  }

  .swal-width{
    width: 550px;
  }

  /************ meta keywords **************/
  .LikeInput{
    /* padding: 11px 0; */
    background: #FCFDFE;
    border: 1px solid #858484;
    background-clip: padding-box;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 5px;
    min-height: 44.7px;
    height: fit-content;
}

  .LikeInput .tagContainer:first-child{
    margin-inline-start: 13px;
  }
  .LikeInput .tagContainer{
    background: linear-gradient(0deg, #F5F5F5, #F5F5F5), linear-gradient(0deg, #F5F5F5, #F5F5F5), linear-gradient(0deg, #F5F5F5, #F5F5F5), linear-gradient(0deg, #F5F5F5, #F5F5F5), #F5F5F5;
    border-radius: 3px;
    padding: 2px;
    margin: 0px 5px;
    color: var(--main-color);
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 10px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    display: inline-block!important;
  }
  .LikeInput .tagContainer .removeTag{
    margin-inline-start: 5px;
    cursor: pointer;
    display: inline-block;
  }

  .LikeInput .tagContainer div{
    display: inline-block;
  }

  .LikeInput input:focus{
    border: none!important;
  }

  .inputMapStyle{
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 400px;
    height: 50px;
    padding: 0 12px;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    outline: none;
    text-overflow: ellipses;
    position: absolute;
    left: 50%;
    transform: translateX(-47%);
    color:#ea4335;
}
.propertySingleImage{
  width: inherit;
  border-radius: 5px;
  height:inherit;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.deletePropImg{
  top: -13px;
  right: -13px;
  cursor: pointer;
}
.propDeleteIcon{
    width: 23px!important;
    background-color: white;
    border-radius: 50%;
}
.special-label{
  display: none;
}
.propNameAtTable ,.propAreaAtTable{
  max-width: 300px;
  white-space: nowrap;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.propAreaAtTable{
  max-width: 140px;
}

.perPageContainer{
  margin-inline-start: 18px;
  /* margin-inline-end: 18px; */

  display: flex;
  align-items: center!important;
}
.perPageContainer .perPageLabel{
  font-size: 14px;
  font-weight: 600;
  line-height:20px;
  margin:0;
  color:#454545;
  text-transform: capitalize;
  margin-inline-end: 13px;
}
.perPageContainer .selectShow, .perPageContainer .selectShow:focus{
  border: 1px solid #858484 !important;
  width:65px;
  height:35px;
  padding: 5px!important;
}
.cityFilter .citySelect{
  border: 1px solid #858484 !important;
  width:160px;
  height:35px;
  padding: 5px!important;
  margin-inline-start: 13px;
}
.rs-picker-toggle-placeholder{
  display: none;
}
.dataRange{
  height:35px;
}
.rs-picker-toggle.rs-btn.rs-btn-default ,.rs-picker-cleanable{
  height: inherit;
}
.rs-picker-toggle{
  border: 1px solid #858484 !important;
}
.rs-picker-cleanable{
  width: 364px!important;
}
.rs-picker-toolbar-ranges{
  display: none !important;
}
.notificationDot {
  padding: 5px;
  background-color: #3751FF;
  border-radius: 50%;
  right: 1px;
}
.rs-picker-toggle-active, .rs-picker.rs-picker-focused {
  box-shadow: unset!important;
  border: 1px solid #A58C3B !important; 
}
input.rs-picker-toggle-textbox{
  border: unset !important;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: var(--yellow)!important;
}
.propSubmit{
  width:180px;
}

.socials_accordion .imageWrapper.imageWrapper2 .removeFooterIcon {
  width: 20px;
  height: 20px;
}
.socials_accordion .imageWrapper.imageWrapper2 .UploadFooterIcon {
  width: 30px;
  height: 30px;
}
.socials_accordion .imageWrapper.imageWrapper2 label{
  display:flex;
  align-items: center;
  justify-content:center;
}
.removeFooterIcon{
  background-color: #fff;
  border-radius: 50%;
}

/* bulk action not selected */
.notSelected{
  cursor:not-allowed!important;
  background-color: white!important;
}
.notSelected span{
  color: gray!important;
}
@media screen and (max-width:700px){
  .formWidth,.selectForm,.pagesForm{
      width:100%;
  }
  .inputMapStyle{
      left: 47%;
  }
  .confirmBtn{
      min-width: 9.063rem;
  }
}