.login-bg-img {
    background: url(`../../../../../assets/images/pexels-kevin-villaruz-1589237.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .login-wrapper {
        width: 63%;
        margin: auto;
        display: flex;
        border-radius: 28px;
        padding-block: 50px;

        .login-form-image {
            width: 47%;
            position: relative;
            z-index: 100;
            border-radius: 28px 0 0 28px;
            object-fit: cover;
        }

        .login-form {
            width: 53%;
            position: relative;
            z-index: 100;
            background-color: #ffffff;
            border-radius: 0 28px 28px 0;
            box-sizing: border-box;
            flex-direction: column;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 85px 70px;

            .formHeader {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 27px;

                img {
                    // width: 84px;
                    height: 96px;
                    margin-bottom: 14px;
                }

                h1 {
                    text-transform: uppercase;
                    color: var(--main-color);
                    letter-spacing: 4px;
                    font-weight: 600;
                    margin-bottom: 4px;
                }

                p {
                    text-transform: uppercase;
                    color: var(--main-color);
                    letter-spacing: 8px;
                    font-weight: 400;
                    font-size: 12.5px;
                    margin-bottom: 0;
                }
            }

            .formLabel {
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 700;
                font-size: 15.7px;
                line-height: 15px;
                letter-spacing: 0.3px;
                text-transform: uppercase;
                color: #9FA2B4;
                margin-bottom: 8px;

            }

            .formInput {
                background: #fcfdfe;
                border: 1.31579px solid #f0f1f7;
                box-sizing: border-box;
                border-radius: 6.57895px;
                padding: 10px;
                width: 100%;
                height: 55px;

                /* :focus{
                    border: none!important;
                } */
            }

            .formInput::placeholder {
                font-family: "Mulish";
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 20px;
                letter-spacing: 0.3px;
                color: rgba(75, 80, 109, 0.7);
                opacity: 0.4;
                text-transform: capitalize;
            }

            .eyeIcon {
                position: absolute;
                cursor: pointer;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);

                svg {
                    fill: #9fa2b4;
                }
            }

            .forgetPass {
                font-family: 'Mulish', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 13.1579px;
                line-height: 17px;
                text-align: right;
                letter-spacing: 0.131579px;
                color: #454545;
                margin: 10px;
                cursor: pointer;
                text-decoration: none;
            }

            .forgetPass:hover {
                font-weight: 600;
                color: #696868;
            }

            .defaultBtn,
            .defaultBtn a {
                background: var(--yellow);
                box-shadow: 0px 5.26316px 26.3158px rgba(165, 140, 59, 0.1);
                padding: 18px;
                border: none;
                color: #FFFFFF;
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                text-transform: capitalize;
                text-decoration: none;
            }

            /**** reset password ***/
            p {
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 400;
                font-size: 18.4211px;
                line-height: 26px;
                text-align: center;
                letter-spacing: 0.394737px;
                color: #4B506D;
                margin-bottom: 31px;
            }

            h5 {
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 700;
                font-size: 25px;
                line-height: 26px;
                text-align: center;
                letter-spacing: 0.394737px;
                color: #4B506D;
                margin-top: 40px;
                margin-bottom: 30px;
            }

            .backLogin {
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 400;
                font-size: 13.1579px;
                line-height: 17px;
                text-align: center;
                letter-spacing: 0.131579px;
                text-decoration-line: underline;
                text-transform: capitalize;
                color: #454545;
                display: inline-block;
                margin-top: 30px;
            }
        }
    }

    @media screen and (max-width: 992px) {
        .login-wrapper {
            width: 80%;
        }
    }

    @media screen and (max-width: 992px) {
        .login-wrapper .login-form {
            width: 100%;
            padding: 85px 20px;
            border-radius: 0 !important;
        }

        .defaultBtn {
            font-size: 16px !important;
        }

        .formLabel {
            font-size: 12.7px !important;
        }

    }

    @media screen and (max-width: 500px) {
        .login-wrapper {
            width: 85%;
        }
    }
}

.login-bg-layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.87);
}