.permissionsWrapper{
    margin-top: 50px;

    .checkLabelWrapper{
        display: flex;
        align-items: center;

        .permissionsCheckbox{
            margin-right: 11px;
        }

        label{
            margin-bottom: 0;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 19px;
            letter-spacing: 0.3px;
            color: #454545;
            text-transform: capitalize;
        }
    }

    p{
        margin-bottom: 25px;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 19px;
        letter-spacing: 0.3px;
        color: #454545;
        text-transform: capitalize;
    }
}