.tableWrapper{
    overflow: auto;
}
table{
    tbody{
        // tr{
        //     // .sliderImg{
        //     //     width: 5.625rem;
        //     // }
        // }
        td{
            .partnerLogoBoredr{
                border: 1.5px solid #DFE0EB;
                border-radius: 50%;
                width: 55px;
                height: 55px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 4px;
                border: 1.5px solid #DFE0EB;

                .userPhoto{
                    cursor: unset;
                    width: 100%;
                    height: 100%; 
                    margin:auto;
                    object-fit: contain;
                    border-radius:50%;
                }
                
            }

            .adminRoleLabel , .userRoleLabel{
                color: #fff;
                text-align: center;
                text-transform: capitalize;
                padding: 4px;
                width: 110px; 
                white-space: nowrap;      
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 400;
                font-size: 14.5px;
                line-height: 16px;
                letter-spacing: 0.3px;
                overflow: hidden;
            }

            .adminRoleLabel{
                background-color: var(--yellow);
            }

            .userRoleLabel{
                background-color: var(--main-color);
            }

            div{
                img , label{
                    margin-inline-end: 14px;
                    cursor: pointer;
                }
            }
        }
        td.desc{
            /* width:25%; */
            max-width: 190px;
        }
        td.desc div.descriptionTd{
            width:100%;
            max-width: 100%;
            display: block;
            white-space: nowrap;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: capitalize;
        }
    }
}

table{
    /* border-top: 1px solid #DFE0EB; */
    border: 1px solid #DFE0EB;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: #f5f5f5!important;
}
// .stripedRow:nth-child(odd){
//     --bs-table-accent-bg: #f5f5f5!important;
//     background-color: #f5f5f5;
// }
.contact-requests table > tbody > tr:nth-child(4n+1) > *, .contact-requests table > tbody > tr:nth-child(4n+2) > *{
    background-color: #f5f5f5;
}.contactActions{
    background-color: #fff;
    border-radius: 50%;
    margin-inline-end: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contactActions img{
    margin-inline-end: 0;
    object-fit: contain;
}
.contactMsgRow {
    border: 1px solid var(--yellow)!important;
}
th{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 1.063rem;
    text-transform: capitalize;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #454545;
}

td{
    vertical-align: middle;
    /* text-transform: capitalize; */
    font-size: 14px;
}
td.hideAtPrint{
    cursor: pointer;
}
.showAllSubSections{
    a{
        text-decoration: none;
        text-transform: capitalize;
    }
}

.table>:not(:first-child){
    border-top: 0!important;
}

.table>:not(caption)>*>*{
    padding: 0.875rem 1.563rem!important;
    border-bottom: 0!important;
    white-space: nowrap;
}

td.sliderImg img{
    object-fit: contain;
    width: 50%; 
    height: 5.625rem;
}

/****** tabs style ********/
.tablinks{
    padding-top: 5px;
    padding-bottom: 5px;
    padding-inline-end: 6px;
    padding-inline-start: 6px;
    margin-inline-end: 6px;
    margin-inline-start: 6px;
    cursor: pointer;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.3px;
    color: #454545;
    text-transform: capitalize;
}
  
.tabcounter{
    color: #9FA2B4;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
}

.nav-tabs{
    border: none!important;
}

.tablinks.active{
    border-bottom: 3px solid var(--yellow);
}

.tablinks.active .tabcounter{
    color: var(--yellow);
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
}

.separator{
    height: 20px;
    width: 1px;
    background-color: #DFE0EB;
}

  /*********** search filter bar ***************/
.searchActionsBar{
    position: relative;
    margin-top: 22px;
    margin-bottom: 14px;
    display: flex;
    // width: 299px;
    /* justify-content: space-between;
    width: 40%; */
    align-items: center;
    flex-wrap: wrap;
    .d-flex{
    flex-wrap: wrap;
    }
}

.tabsBarBtn , .tabsBarBtn:hover{
    text-decoration: none;
    background-color: var(--yellow);
    color: #fff;
}

.searchActionsBar .searchInputWrapper{
    position: relative;
    /* width: inherit; */
    margin-inline-end: 10px;
}

.searchInputWrapper .barSearchInput {
    position: relative;
    background: #ffffff;
    border: 1px solid #dfe0eb;
    box-sizing: border-box;
    border-radius: 3px;
    height: 35px;
    width: 18.688rem;
    padding-inline-start: 2rem!important;
    margin-top:10px;
}

/*.inputPlaceholder {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #FFFFFF;
    padding: 0 10px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #C5C7CD;
  } */

.searchInputWrapper .barSearchIcon {
    position: absolute;
    left: 10px;
    top:65%;
    transform: translateY(-50%);
}
.dataRange{
    margin-top:10px;
}
.applySearchBtn{
    background: #FFFFFF;
    border: 1px solid var(--yellow);
    color: var(--yellow);
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    padding: 5.5px;
    margin-inline-start: 0.813rem;
    cursor: pointer;
    width: 5.938rem!important;
    margin-top:10px;
}
.applySearchBtn:hover{
    background: var(--yellow);
    color: #ffffff;
}
.perPageContainer {
    align-items: center!important;
    display: flex;
    margin-inline-start: 1.125rem;
}
.perPageContainer .perPageLabel {
    color: #454545;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    -webkit-margin-start: 0px;
    margin-inline-start: 0px!important;
    text-transform: capitalize;
    -webkit-margin-end: 13px;
    margin-inline-end: 13px!important;
    margin-top:10px!important;
    margin-bottom:0px!important;

}
.perPageContainer .selectShow, .perPageContainer .selectShow:focus {
    border: 1px solid #858484!important;
    height: 35px;
    padding: 5px!important;
    width: 65px;
    margin-top:10px;
}

[data-rbd-drag-handle-context-id]{
    width: 100% !important;
}

@media screen and (max-width:800px) {

    .table>:not(caption)>*>*{
        border-bottom: 0!important;
    }
}

@media screen and (max-width:600px) {
    td.sliderImg img{
        width: 100%;
    }
    .searchInputWrapper .barSearchInput{
        padding-inline-start: 3rem !important;
    }
}
@media screen and (max-width:427px){
    .bulkDropdown ,.searchInputWrapper .barSearchInput,.rs-picker-cleanable{
        width:255px !important;
    }
}
.alert-danger.alert-dismissible{
    margin-inline-start: 10px;
}
