.actionCardSideWrapper{
    width: 81%;
    min-height: 500px;
    background-color: #fff;
    border: 1px solid #DFE0EB;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 50px;

    div{
        h5{        
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 700;
            font-size: 19px;
            line-height: 25px;
            color: #3F3F3F;
            text-transform: capitalize;
            margin-bottom: 0;
        }
    }

    form{
        margin-top: 39px;
        
        .formInputsWrapper{
            width: 50%;
        }

        label{
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.3px;
            text-transform: uppercase;
            color: #9FA2B4;
            margin-bottom: 8px;
        }

        input , input:focus , select , select:focus{
            background: #FCFDFE;
            border: 1px solid #858484;
            box-sizing: border-box;
            border-radius: 5px;
            padding: 11px 15px;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3px;
        }

        .formSelectbox{
            background: url(../../assets/images/ExpandArrow.png) no-repeat;
            background-color: #FCFDFE;
            width: 100%;
            height: 50px;
            border: 1px solid #858484;
            box-sizing: border-box;
            border-radius: 5px;
            overflow: hidden;
            display: inline-block;
            position: relative;
            padding: 10px;
        }
        .formSelectbox select{
            width: 100%;
            height: 50px;
            border: none;
            border-radius: 5px;
            color: #00263C;
            font-size: 1rem;
            font-weight: 500;
            text-transform: capitalize;
            font-family: 'Poppins', sans-serif;
            -webkit-appearance: none;
            background-color: transparent;
            position: absolute;
            top: 0;
            left: 0;
            padding: 15px;
        }
        .formSelectbox select:focus{
            outline: 0;
        }
        
        input::placeholder , textarea::placeholder{
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3px;
            color: #4B506D;
            opacity: 0.4;
            text-transform: uppercase;
        }

        textarea{
            background-color: #FCFDFE;
            border: 1px solid #858484;
            box-sizing: border-box;
            border-radius: 5px;
            padding: 11px 15px;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3px;
            height: 70px;
        }

        .imageWrapper{
            width: 100%;
            height: 130px;
            padding: 0!important;
            background: #fcfdfe;
            border: 0.5px solid #60626e;
            box-sizing: border-box;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            /* img{
                width: 55px;
                height: 40px;
            } */

            span{
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 700;
                font-size: 9.17241px;
                line-height: 13px;
                text-align: center;
                letter-spacing: 0.131034px;
                color: #454545;
            }

            div{
                width: 101%;
                border-radius: 5px;
                height: 130px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
            }

            .reloadAnotherIcon{
                top: -13px;
                right: -13px;
                cursor: pointer;
                border-radius: 50%;
                background-color: #FFF;
                img{
                    width: 23px;
                    height: fit-content;
                }
            }
        }

        .deleteBtn{
            padding: 10px 65px;
        }
    }
}
@media screen and (max-width:1200px){
    .actionCardSideWrapper form .formInputsWrapper{
        width: 70%;
    }
}
@media screen and (max-width:902px){
    .actionCardSideWrapper form .formInputsWrapper{
        width: 100%;
    }
}