.socials_accordion{
    background-color: #FCFDFE!important;
    border: 1px solid #454545!important;
    box-sizing: border-box;
    border-radius: 5px!important;
    margin-bottom: 10px;

    .accordion_header_container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 12px;

        img{
            width: 12px;
        }

        p{
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: 0.3px;
            color: #454545;
            margin-left: 12px;
            text-transform: capitalize;
        }

        button{
            width: 50px;
        }

        .accordion-button:not(.collapsed) , .accordion-button:focus {
            background-color: unset!important;
            box-shadow: unset!important;
            color: var(--main-color);
        }

        .accordion-button:not(.collapsed)::after {
            background-image: url(../../assets/icons/ExpandArrow.svg);
            transform: rotate(360deg);
        }
    }

    .imageWrapper.imageWrapper2 label {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .imageWrapper.imageWrapper2 .removeFooterIcon {
        width: 20px;
        height: 20px;
    }
    .imageWrapper img{
        width: 30px;
        height: 30px;
    }
    .imageWrapper{
        width: 55px!important;
        height: 55px!important;
        padding-top: 13px!important;
        padding-bottom: 13px!important;

    }
    .globalRemoveImage {
        top: -24px;
    }

    .deleteSocial{
        color: #D35D5D;
        border: none;
        background-color: transparent;
        text-transform: capitalize;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 15px;
        letter-spacing: 0.2px;
        color: #D35D5D;
    }
}

.addSocial{
    width: 42px;
    height: 42px;
    background-color: #FCFDFE;
    border: 1px solid #454545;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    p{
        font-size: 35px;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-style: normal;
        line-height: 40px;
        margin-bottom: 0;
        cursor: pointer;
    }
}