.aboutus{
    min-height: fit-content!important;

    h5{
        color: var(--yellow);
    }

    form{
        margin-top: 25px;

        .imageWrapper{
            padding-top: 13px;
            padding-bottom: 13px;
            background: #fcfdfe;
            border: 1px solid #60626e;
            box-sizing: border-box;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            img{
                width: 55px;
                height: 40px;
            }

            span{
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 700;
                font-size: 9.17241px;
                line-height: 13px;
                text-align: center;
                letter-spacing: 0.131034px;
                color: #454545;
            }
        }

        textarea{
            height: 110px;
            resize: none;
        }

        button{
            width: 180px;
        }

    }

    .singleQuery{
        width: 100%;
        /* height: 46px; */
        padding: 16px 40px;
        background-color: #F6F6F6;
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;

        p{
            margin-bottom: 0;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 700;
            font-size: 19px;
            line-height: 25px;
            text-transform: capitalize;
            color: #3F3F3F;
        }

        div{
            img{
                cursor: pointer;
            }
            
            img:first-of-type{
                margin-right: 12px;
            }
        }
    }

    a , a:hover{
        text-decoration: none;
        color: #fff;
    }
}