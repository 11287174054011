.overViewCard , .latestData{
    background: #ffffff;
    border: 1.79851px solid #dfe0eb;
    box-sizing: border-box;
    border-radius: 8.99254px;
    align-items: center;
    padding:25px 40px;
    text-decoration: none;
}
.overViewCard{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.overViewCard:hover{
    text-decoration: none;
}
.overViewCard .details{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
    letter-spacing: 0.719403px;
    color: #9FA2B4;
    text-transform: capitalize;
    /* white-space: nowrap; */
}
.overViewCard .number{
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 90px;
    text-align: center;
    letter-spacing: 1.79851px;
    color: #000;
    margin-top: 10px;
}

.overViewCard:hover{
    border: 1.79851px solid var(--yellow);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.overViewCard:hover.overViewCard .details,.overViewCard:hover.overViewCard .number{
    color: var(--yellow);
}

.latestData{
    height: fit-content;
}
.latestData .latestHeader{
    width: 100%;
    display:flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.latestData .latestHeader p:first-of-type{
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 24px;
    color: #000;
    text-transform: capitalize;
}

.latestData .latestHeader a{
    color: var(--yellow);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    text-transform: capitalize;
    text-decoration: none;
}
.latestData .latestHeader a:hover{
    color: var(--yellow)c4;
}

.latestData .allTitlesOfLatest .titleOfLatest{
    width: 100%;
    display: flex;
    justify-content:space-between;
    /* border-bottom: 1px solid #DFE0EB; */
    padding-bottom: 18px;
    padding-top: 20px;
    max-width:98%;
}

.latestData .allTitlesOfLatest .titleOfLatest p{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: #252733ec;
    margin-bottom: 0;
    white-space: nowrap;
    width: 95%;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.latestData .allTitlesOfLatest hr{
    margin: 0 -40px;
}

.latestData .allTitlesOfLatest .oneOfLatest:last-child hr{
    display: none!important;
}
@media screen and (max-width:450px){
    .overViewCard, .latestData{
        padding: 25px 15px;
    }
    .latestData .allTitlesOfLatest hr {
        margin: 0 -15px;
    }
}