.asideWrapper {
    width: 100%;
    height: inherit;
    padding: 1.875rem 0;
    background-color: var(--main-color);
    visibility: visible !important;

    .asideLogo {
        width: 135px;
    }

    .asideList {
        list-style: none;
        padding-inline-start: 0;

        .navLink,
        .nestedNavLink {
            display: flex;
            align-items: center;
            text-decoration: none;
            padding-inline-start: 1.75rem;
            padding-top: 0.938rem;
            padding-bottom: 0.938rem;
            font-family: "Mulish", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 0.938rem;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: #ffffff;
        }

        .nestedNavLink.lastOne {
            padding-bottom: 3.938rem;
        }

        .navLink {
            margin-top: 12px;
        }

        img {
            height: 1.75rem;
        }

        .badgeNumber {
            background-color: var(--yellow);
            border-radius: 100%;
            width: 1.375rem;
            height: 1.375rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff !important;
            font-size: 13px !important;
            font-weight: 500 !important;
        }
    }

    .asideSplitter {
        margin-inline-start: 1.563rem;
        margin-top: 28px;
        margin-bottom: 28px;
        width: 18.1%;
        color: #ffffff;
        background-color: #ffffff;
        font-size: 1.563rem;
        height: 2px !important;
    }

    .sideBarClose {
        margin-inline-start: auto;
    }
}


.asideDropdown {
    list-style: none;

    .nestedNavLink.active {
        color: var(--yellow) !important;
        border-inline-start: none;
        font-weight: 900;
    }

    li a {
        text-transform: capitalize;
    }
}

.navLink.active {
    background-color: var(--yellow) !important;
    color: #ffffff !important;
    // border-inline-start: 3px solid var(--yellow);
    /* border-radius: 32px; */
}

.navLink:hover {
    color: var(--yellow) !important;
    transition: 0.4s;
}

.navLink.active:hover {
    color: #fff !important;
    transition: 0.4s;
}

.nestedNavLink:hover {
    color: var(--yellow) !important;
    transition: 0.4s;
}

ul.asideList {
    padding-inline-end: 0 !important;
    padding-inline-start: 0 !important;
}

ul.asideDropdown {
    padding-inline-start: 2rem !important;
}

.capitalWord {
    text-transform: capitalize;
    padding-inline-start: 1rem;
    white-space: nowrap;
}
.sideBarClose {
    outline: none;
    box-shadow: none;
    &:focus{
        outline: none;
        box-shadow: none !important;
    }
}
@media screen and (max-width :1200px) {
    .asideWrapper .asideLogo {
        width: 110px;
    }
}

@media screen and (max-width :500px) {

    .asideWrapper .asideList .navLink,
    .asideWrapper .asideList .nestedNavLink {
        padding-inline-start: 0.75rem;
    }

    ul.asideDropdown {
        padding-inline-start: 1rem !important;
    }
}

@media screen and (max-width :400px) {
    .capitalWord {
        text-transform: capitalize;
        padding-inline-start: 0.5rem;
    }
}