.addArea{
    form{
        margin-top: 39px;
        
        .formInputsWrapper{
            width: 50%;
        }

        label{
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.3px;
            text-transform: uppercase;
            color: #9FA2B4;
            margin-bottom: 8px;
        }

        input , input:focus {
            background: #FCFDFE;
            border: 1px solid #858484;
            box-sizing: border-box;
            border-radius: 5px;
            padding: 11px 15px;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3px;
        }
        
        input::placeholder , textarea::placeholder{
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3px;
            color: #4B506D;
            opacity: 0.4;
            text-transform: uppercase;
        }

        textarea{
            background-color: #FCFDFE;
            border: 1px solid #858484;
            box-sizing: border-box;
            border-radius: 5px;
            padding: 11px 15px;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3px;
            height: 80px;
        }

        .imageWrapper{
            width: 100%;
            height: 130px;
            padding: 0!important;
            background: #fcfdfe;
            border: 0.5px solid #60626e;
            box-sizing: border-box;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            img{
                width: 55px;
                height: 40px;
            }

            span{
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 700;
                font-size: 9.17241px;
                line-height: 13px;
                text-align: center;
                letter-spacing: 0.131034px;
                color: #454545;
            }

            div{
                width: 101%;
                border-radius: 5px;
                height: 130px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
            }

            .reloadAnotherIcon{
                top: -20px;
                right: -15px;
                cursor: pointer;

                img{
                    width: 23px;
                }
            }
        }

        button{
            background: #454545;
            border-radius: 32px;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #fff;
            padding: 10px 40px;
            border: none;
            text-transform: capitalize;
        }

        .deleteBtn{
            background-color: #CD3D3D;
            margin-left: 17px;
            padding: 10px 65px;
        }
    }
}
.cityFilter{
    margin-top: 10px;
}
@media screen and (max-width:992px) {
    .addArea form .formInputsWrapper{
        width:70%;
    }
    .addArea form button{
        padding: 7px 20px;
        font-size: 12px;
    }
}
@media screen and (max-width:510px){
    .cityFilter .citySelect{
        margin-inline-start: 2px;
    }
    .addArea form .formInputsWrapper{
        width:80%;
    }
    
}