.homeSectionWrapper{
    padding: 0 0 9px;
    border: 1px solid #DFE0EB;
    border-radius: 8px;
    margin-top: -8px;
    margin-bottom: 45px;

    .sectionTitle{
        background-color: #F6F6F6;
        padding: 0.58rem 2.5rem;
        margin-bottom: 1.25rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        h5{       
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 700;
            font-size: 1.125rem;
            line-height: 23px;
            letter-spacing: 0.3px;
            color: #000000;

            img{
                margin-left: 15px;
            }
        }

        .confirmBtn{
            text-decoration: none;
            color: #fff;
        }

        .updateBtn{
            width: 170px;
        }

    }

    table{
        tbody{
            td{
                .sliderImg{
                    width: 90px;
                }
                div{
                    img{
                        margin-right: 14px;
                    }
                }
            }
        }
    }
    
    .formContainer{
        padding: 24px 20px;

        textarea{
            height: 100px;
            resize: none;
        }
    }
}
@media screen and (max-width:600px){
    
    .homeSectionWrapper .sectionTitle .updateBtn{
        width: 100px;
    }
    .homeSectionWrapper .sectionTitle{
        padding: 9px 15px;
    }
}