/* form{ */
form {
    margin-top: 25px;
}

.imageWrapper {
    width: 11.25rem !important;
    height: 8.75rem !important;
    padding: 0 !important;
    background: #fcfdfe;
    border: 0.5px solid #60626e;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
        width: 55px;
        height:23px;
    }
    span {
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 700;
        font-size: 9.17241px;
        line-height: 13px;
        text-align: center;
        letter-spacing: 0.131034px;
        color: #454545;
    }

    div {
        width: 100%;
        border-radius: 5px;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .reloadAnotherIcon {
        top: -20px;
        right: -15px;
        cursor: pointer;

        img {
            width: 23px;
        }
    }
}

.imageWrapper label {
    cursor: pointer;
}

// button{
//     width: 180px;
// }

input:focus,
select:focus,
textarea:focus,
.phoneInput input:focus {
    border: 1px solid var(--yellow) !important;
    outline: none !important;
    box-shadow: none !important;
}

input[type=checkbox] {
    cursor: pointer;
}

/****** labels , inputs and textarea ********/
label {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #9FA2B4;
    margin-bottom: 8px;
    white-space: nowrap;
}

input,
textarea,
.phoneInput input,
.react-tel-input .form-control {
    box-sizing: border-box !important;
    background: #FCFDFE !important;
    border: 1px solid #858484 !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    padding: 0.688rem 0.813rem !important;
    font-family: 'Mulish' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    letter-spacing: 0.3px !important;
    color: var(--main-color) !important;
}

textarea {
    height: 6.25rem;
    resize: none !important;
}

.react-tel-input .form-control {
    width: 100% !important;
    height: unset !important;
    padding: 0.688rem 3.125rem !important;
    direction: ltr !important;
}

.react-tel-input .form-control::placeholder {
    color: transparent
}

.react-tel-input .flag-dropdown {
    background: #FCFDFE !important;
    border: 1px solid #858484 !important;
    direction: ltr !important;
}

input::placeholder,
textarea::placeholder {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.3px;
    color: #4B506D;
    opacity: 0.4;
    text-transform: capitalize;
}

.selectParent {
    width: 100%;
    height: 2.731rem;
    overflow: hidden;
    background: url(../assets/images/ExpandArrow.png) no-repeat;
    background-position: calc(100% - 10px) center;
    background-color: #FCFDFE;
    display: inline-block;
    position: relative;
    border-radius: 5px;
}

.selectParent select,
.selectParent select:focus {
    background: transparent;
    appearance: none;
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    width: 100%;
    height: 2.731rem;
    border-radius: 5px;
    border: 1px solid #858484;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    text-transform: capitalize;
    line-height: 1.25rem;
    letter-spacing: 0.3px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    padding: 0.688rem 0.938rem;
}

.selectParent select option:disabled {
    color: rgba(75, 80, 109, 0.4);
}

/***** confirm btn ******/
.confirmBtn {
    background-color: #454545;
    border-radius: 32px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: center;
    letter-spacing: 0.2px;
    color: #fff;
    padding: 0.625rem 2.5rem;
    border: none;
    text-transform: capitalize;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s;
    min-width: 11.063rem;
    margin-top: 10px;
}

.confirmBtn:hover {
    color: #fff;
    background-color: #454545e9;
}

.confirmBtn[disabled] {
    background-color: #454545b0;
    color: #fff;
    cursor: not-allowed;
}

.updateBtn {
    margin-top: 0;
}

.deleteBtn {
    background-color: #CD3D3D;
    margin-inline-end: 17px;
    border-radius: 32px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #fff;
    padding: 0.625rem 2.5rem;
    border: none;
    text-transform: capitalize;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s;
    min-width: 11.063rem;
    max-width: 11.063rem !important;
    white-space: nowrap;
}

.deleteBtn:hover {
    color: #fff;
    background-color: #cd3d3ddb;
}

.eyeIcon {
    position: absolute;
    cursor: pointer;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);

    svg {
        fill: #9fa2b4;
    }
}

.validateToDelete {
    font-size: 12px;
    font-weight: 500;
}

.LikeInput {
    /*  padding: 2px 0; */
    background: #FCFDFE;
    border: 1px solid #858484;
    background-clip: padding-box;
    appearance: none;
    border-radius: 5px;
    min-height: 2.75rem;
    overflow: auto;
}

.LikeInput .tagContainer:first-child {
    margin-inline-start: 13px;
}

.LikeInput .tagContainer {
    background: linear-gradient(0deg, #F5F5F5, #F5F5F5), linear-gradient(0deg, #F5F5F5, #F5F5F5), linear-gradient(0deg, #F5F5F5, #F5F5F5), linear-gradient(0deg, #F5F5F5, #F5F5F5), #F5F5F5;
    border-radius: 3px;
    padding: 2px;
    margin: 0px 5px;
    color: var(--main-color);
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 10px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    display: inline-block !important;
}

.LikeInput .tagContainer .removeTag {
    margin-inline-start: 5px;
    cursor: pointer;
    display: inline-block;
}

.LikeInput .tagContainer div {
    display: inline-block;
}

.LikeInput input:focus {
    border: none !important;
}

.inputMapStyle {
    border: 1px solid transparent;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgb(0 0 0 / 30%);
    box-sizing: border-box;
    color: #ea4335;
    font-size: 14px;
    height: 50px;
    left: 60%;
    outline: none;
    padding: 0 12px;
    position: absolute;
    text-overflow: ellipses;
    -webkit-transform: translateX(-47%);
    transform: translateX(-47%);
    width: 25rem;
}

.switch {
    display: inline-block;
    height: 15px;
    position: relative;
    width: 28px;
}

.switch input {
    height: 0;
    opacity: 0;
    width: 0;
}

.slider.round {
    border-radius: 15px;
}

.slider,
.slider:before {
    position: absolute;
    transition: .4s;
}

.slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    right: 0;
    top: 0;
}

.slider.round:before {
    border-radius: 50%;
}

.slider:before {
    background-color: #fff;
    bottom: 2px;
    content: "";
    height: 11px;
    left: 3.5px;
    width: 11px;
}

.slider,
.slider:before {
    position: absolute;
    transition: .4s;
}

input:checked+.slider {
    background-color: #36d2a3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(11px);
    transform: translateX(11px);
}

.labelForCheck {
    margin-inline-start: 15px;
}

.phoneInput,
.phoneInput input,
.react-tel-input .selected-flag,
.react-tel-input .country-list {
    direction: ltr !important;
}

.globalImageWrapper {
    width: 11.25rem !important;
    height: 8.75rem;
    padding: 0 !important;
    background: #fcfdfe;
    border: 0.5px solid #60626e;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-inline-end: 14px;
}

.globalImageWrapper .cloudIcon {
    width: 3.438rem;
    height: 2.5rem;
}

.globalImageWrapper span {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 9.17241px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.131034px;
    color: #454545;
    text-transform: none;
}

.globalPreviewedImage {
    width: calc(100% + 2px);
    height: 142px;
    border-radius: 5px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.globalRemoveImage {
    position: absolute;
    top: -13px;
    right: -13px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


/* } */

.arabicWrapper form .eyeIcon {
    right: unset;
    left: 20px;
}

.arabicWrapper form .selectParent {
    background-position: 10px center;
}

.arabicWrapper form .react-tel-input .selected-flag .arrow {
    left: 10px
}

.languageDropdown {
    align-items: center;
    background: #fff;
    border: 1px solid #454545;
    border-radius: 3px;
    box-sizing: border-box;
    color: #9fa2b4;
    display: flex;
    font-size: 11px;
    justify-content: space-between;
    line-height: 13px;
    margin-inline-end: 10px;
    padding: 0.563rem 0.625rem;
    text-transform: uppercase;
    // width: 10.938rem;
    margin-top: 10px;
}

.languageDropdown img:first-child,
.languageDropdown span {
    margin-inline-end: 8px;
    white-space: nowrap;
}

.languageTabs {
    padding-inline-start: unset;
}

.custom-bulk-dropdown li,
.custom-lang-dropdown li {
    color: #454545;
    color: var(--main-color);
    cursor: pointer;
    font-family: Mulish;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    padding: 4px 1px;
    text-transform: capitalize;
}

.custom-lang-dropdown li span.active {
    color: #102693;
}

.custom-bulk-dropdown li:hover,
.custom-lang-dropdown li:hover {
    background-color: #45454516;
}

/* .gmnoprint{
    display: none;
}
   */

.react-tel-input .selected-flag {
    border-radius: 5px 0 0 5px !important;
}

.react-tel-input .flag-dropdown {
    border-radius: 5px 0 0 5px !important;
}

.arabicWrapper .form-select {
    background-position: left 0.75rem center;
}

.formWidth {
    width: 70%;
}

.selectForm {
    width: 47.5%;
}

.pagesForm {
    width: 42%;
}

.propertySettings {
    width: 33%;
}

.marginStart {
    padding-inline-start: 0.938rem;
}

.marginEnd {
    padding-inline-end: 0.938rem;
}

.iconAndLabelContainer {
    margin-bottom: 0.688rem;
}

.socialIcon {
    width: 1.25rem;
    height: 1.25rem;
    margin-inline-end: 6px;
}

@media screen and (max-width:1024px) {
    .inputMapStyle {
        top: 55px !important;
        width: 21rem !important;
    }
}

@media screen and (max-width:992px) {

    .formWidth,
    .pagesForm,
    .propertySettings {
        width: 80%;
    }

    .topWidth {
        width: 70%;
    }

    .selectForm {
        width: 60%;
    }

    .individualImageWrapper {
        width: 130px;
        height: 130px;
    }

    .label {
        font-size: 10px;
    }
}

@media screen and (max-width:700px) {

    .formWidth,
    .selectForm,
    .pagesForm,
    .propertySettings {
        width: 100%;
    }

    .topWidth {
        width: 70%;
    }

    .inputMapStyle {
        left: 47%;
    }

    .individualImageWrapper {
        width: 100px;
        height: 100px;
    }
}

@media screen and (max-width:1600px) {
    * {
        font-size: 12.5px;
    }
}

@media screen and (max-width:1400px) {
    * {
        font-size: 12px;
    }
}

@media screen and (max-width:1200px) {
    * {
        font-size: 11.5px;
    }
}

@media screen and (max-width:950px) {
    * {
        font-size: 11px;
    }

    label {
        font-size: 10px !important;
    }

    .pageSubtitle {
        font-size: 18px;
    }

    .react-tel-input .form-control {
        padding: 0.688rem 5.125rem !important;

    }
}

@media screen and (max-width:600px) {
    td {
        font-size: 12px !important;
    }

    input:checked+.slider:before {
        transform: translate(11px, 0px);
    }

    .switch {
        width: 25px;
    }
    .confirmBtn{
        width: 10.063rem;
        padding: 0.6rem 0.1rem
    }
}

@media screen and (max-width:500px) {
    .topWidth {
        width: 55%;
    }

    .individualImageWrapper {
        width: 100px;
        height: 100px;
    }

    .individualImageWrapper>img {
        width: 40px;
    }
    .slider:before{
        left: 1.5px;
    }
}

@media screen and (max-width:450px) {
    * {
        font-size: 9px;
    }

    label {
        font-size: 7px !important;
    }
}