.asidePart {
    width: 15%;
    min-height:100vh;
    overflow: auto !important;
    background: var(--main-color) !important;
}
 
.remainPart {
    width: 85%;
    background-color: #FCFDFE;
    padding: 0.625rem 3.125rem;
    display: flex;
    flex-direction: column;
}
.renderdComponents {
    height:100%;
    /* background: #ffffff; */
    /* border: 1px solid #dfe0eb; */
    box-sizing: border-box;
    border-radius: 8px;
    /* padding:50px; */
}
@media screen and (max-width:992px) {
    .asidePart {
        width: 35% !important;
    }
    .remainPart{
        width: 100%;
    }
}
@media screen and (max-width:600px) {
    .asidePart {
        width: 55% !important;
    }
}