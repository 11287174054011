.subside_wrapper {
    width: 16%;
    min-height: calc(100vh - 180px);
    background-color: #FFFFFF;
    border: 1px solid #DFE0EB;
    box-sizing: border-box;
    border-radius: 8px;
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
    padding-inline-start: 1.875rem;

    h5 {
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        color: #3F3F3F;
        margin-bottom: 35px;
        text-transform: capitalize;
    }

    .oneItemWrapper {
        display: flex;
        align-items: center;
        margin-bottom: 18px;

        img {
            margin-right: 8px;
        }

        .arrayItems {
            text-decoration: none;
            display: block;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 19px;
            letter-spacing: 0.3px;
            color: #454545;
            text-transform: capitalize;
            cursor: pointer;
            transition: 0.4s;
        }

        .arrayItems:hover {
            font-weight: 600;
            font-size: 16px;
        }
    }


    .addLabel,
    .addLabel:hover {
        text-decoration: none;
        text-transform: capitalize;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 900;
        font-size: 15.5px;
        line-height: 19px;
        letter-spacing: 0.3px;
        color: #454545;
    }

}

@media screen and (max-width:900px) {
    .subside_wrapper {
        min-height: 200px;
        width: 100%;
        margin-bottom: 10px;
    }

    .subsideItemsWrapper {
        display: flex;
        flex-wrap: wrap;
    }

    .oneItemWrapper {
        margin-inline-start: 10px;

        ::before {
            content: "\A";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #3F3F3F;
            display: inline-block;
            margin-inline-end: 5px;
        }
    }

    .subside_action_wrapper {
        flex-direction: column;
    }

    .actionCardSideWrapper {
        width: 100% !important;
        padding: 25px !important; 
    }
}